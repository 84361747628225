// =====================================Font and line-height scss================================

// .container {
//   width: $full-width;
//   max-width: 1428px;
//   @include padding(0 16px);
//   @include margin(0 auto);
//   box-sizing: border-box;

//   &.with-bottom-panel {
//     @include paddingBottom(24px);
//   }
// }

.rotate--minus-90 {
  transform: rotate(-90deg);
}

.rotate--45 {
  transform: rotate(45deg);
}

.rotate--90 {
  transform: rotate(90deg);
}

.no--margin {
  @include margin(0 !important);
}

.no--padding {
  @include padding(0 !important);
}

.no--border {
  border: 0 !important;
}

.no--border-radius {
  border-radius: 0 !important;
}

.no--shadow {
  box-shadow: none !important;
}

.no--bg {
  background: transparent !important;
}

.m--0-auto {
  @include margin(0 auto !important);
}

.min-width--auto {
  min-width: auto !important;
}

.width--auto {
  width: auto !important;
}

.width--fit-content {
  width: fit-content !important;
}

.width--full {
  width: $full-width !important;
}

.width--80 {
  width: 80% !important;
}

.width--90 {
  width: 90% !important;
}

.width--half {
  width: 50% !important;
}

.max-width--half {
  max-width: 50% !important;
}

.width--half-with-margin {
  width: calc(50% - 4px) !important;
}

.width--6 {
  width: 6px !important;
}

.width--8 {
  width: 8px !important;
}

.width--10 {
  width: 10px !important;
}

.width--12 {
  width: 12px !important;
}

.width--14 {
  width: 14px !important;
}

.width--15 {
  width: 15px !important;
}

.width--16 {
  width: 16px !important;
}

.width--18 {
  width: 18px !important;
}

.width--20 {
  width: 20px !important;
}

.width--22 {
  width: 22px !important;
}

.width--24 {
  width: 24px !important;
}

.width--25 {
  width: 25px !important;
}

.width--28 {
  width: 28px !important;
}

.width--30 {
  width: 30px !important;
}

.width--32 {
  width: 32px !important;
}

.width--35 {
  width: 35px !important;
}

.width--36 {
  width: 36px !important;
}

.width--40 {
  width: 40px !important;
}

.width--48 {
  width: 48px !important;
}

.width--50 {
  width: 50px !important;
}

.width--52 {
  width: 52px !important;
}

.width--54 {
  width: 54px !important;
}

.width--100 {
  width: 100px;
}

.max-width--896 {
  width: 896px;
}

.height--full {
  height: $full-height !important;
}

.height--8 {
  height: 8px !important;
}

.height--12 {
  height: 12px !important;
}

.height--16 {
  height: 16px !important;
}

.height--20 {
  height: 20px !important;
}

.height--24 {
  height: 24px !important;
}

.height--25 {
  height: 25px !important;
}

.height--28 {
  height: 28px !important;
}

.height--30 {
  height: 30px !important;
}

.height--32 {
  height: 32px !important;
}

.height--35 {
  height: 35px !important;
}

.height--40 {
  height: 40px !important;
}

.height--48 {
  height: 48px !important;
}

.height--50 {
  height: 50px !important;
}

.height--54 {
  height: 54px !important;
}

.min-height--64 {
  min-height: 64px !important;
}

.min-height--70 {
  min-height: 70px !important;
}

.height--72 {
  height: 72px !important;
}

.height--100 {
  height: 100px !important;
}

.height--200 {
  height: 200px !important;
}

.min-height--inherit {
  min-height: inherit;
}

.box-size--border-box {
  box-sizing: border-box;
}

.hide {
  display: none !important;
}

.show {
  display: block;
}

//hide till width 992px
.hide--lg {
  display: none;
}

//show from width 1920px
.show--xl {
  display: none;
}

//hide from width 1920px
.hide--xl {
  display: block;
}

//show from width 992px
.show--lg {
  display: block;
}

//hide till width 768px
.hide--md {
  display: none;
}

//show after width 768px
.show--md {
  display: block;
}

//hide till width 576px
.hide--sm {
  display: none;
}

//show after width 576px
.show--sm {
  display: block;
}

//hide till width 425px
.hide--xs {
  display: none;
}

//show after width 425px
.show--xs {
  display: block;
}

.visibility--hidden {
  visibility: hidden;
}

.break-word--all {
  word-break: break-all;
}

.break-word--word {
  word-break: break-word;
}

.padding--8 {
  @include padding(8px !important);
}

.padding--16 {
  @include padding(16px !important);
}

.padding--10 {
  @include padding(10px !important);
}

.mt--0 {
  @include marginTop(0 !important);
}

.mt--2 {
  @include marginTop(2px !important);
}

.mt--4 {
  @include marginTop(4px !important);
}

.mt--5 {
  @include marginTop(5px !important);
}

.mt--8 {
  @include marginTop(8px);
}

.mt--10 {
  @include marginTop(10px);
}

.mt--12 {
  @include marginTop(12px);
}

.mt--15 {
  @include marginTop(15px !important);
}

.mt--16 {
  @include marginTop(16px !important);
}

.mt--20 {
  @include marginTop(20px !important);
}

.mt--24 {
  @include marginTop(24px !important);
}

.mt--25 {
  @include marginTop(25px !important);
}

.mt--30 {
  @include marginTop(30px !important);
}

.mt--48 {
  @include marginTop(48px !important);
}

.ml--n-2 {
  @include marginLeft(2px !important);
}

.ml--auto {
  @include marginLeft(auto);
}

.ml--0 {
  @include marginLeft(0 !important);
}

.ml--4 {
  @include marginLeft(4px);
}

.ml--5 {
  @include marginLeft(5px);
}

.ml--8 {
  @include marginLeft(8px);
}

.ml--10 {
  @include marginLeft(10px);
}

.ml--12 {
  @include marginLeft(12px);
}

.ml--14 {
  @include marginLeft(14px);
}

.ml--15 {
  @include marginLeft(15px);
}

.ml--16 {
  @include marginLeft(16px);
}

.ml--20 {
  @include marginLeft(20px);
}

.ml--25 {
  @include marginLeft(25px);
}

.ml--30 {
  @include marginLeft(30px);
}

.ml--50 {
  @include marginLeft(50px);
}

.mr--auto {
  @include marginRight(auto !important);
}

.mr--0 {
  @include marginRight(0 !important);
}

.mr--4 {
  @include marginRight(4px);
}

.mr--5 {
  @include marginRight(5px);
}

.mr--8 {
  @include marginRight(8px);
}

.mr--10 {
  @include marginRight(10px);
}

.mr--12 {
  @include marginRight(12px);
}

.mr--13 {
  @include marginRight(13px);
}

.mr--14 {
  @include marginRight(14px);
}

.mr--15 {
  @include marginRight(15px !important);
}

.mr--16 {
  @include marginRight(16px !important);
}

.mr--20 {
  @include marginRight(20px !important);
}

.mr--30 {
  @include marginRight(30px);
}

.mb--1 {
  @include marginBottom(1px !important);
}

.mb--2 {
  @include marginBottom(2px !important);
}

.mb--3 {
  @include marginBottom(3px !important);
}

.mb--0 {
  @include marginBottom(0 !important);
}

.mb--4 {
  @include marginBottom(4px);
}

.mb--5 {
  @include marginBottom(5px);
}

.mb--8 {
  @include marginBottom(8px);
}

.mb--10 {
  @include marginBottom(10px !important);
}

.mb--12 {
  @include marginBottom(12px !important);
}

.mb--13 {
  @include marginBottom(13px !important);
}

.mb--15 {
  @include marginBottom(15px !important);
}

.mb--16 {
  @include marginBottom(16px !important);
}

.mb--18 {
  @include marginBottom(18px !important);
}

.mb--20 {
  @include marginBottom(20px !important);
}

.mb--23 {
  @include marginBottom(23px !important);
}

.mb--24 {
  @include marginBottom(24px !important);
}

.mb--25 {
  @include marginBottom(25px !important);
}

.mb--30 {
  @include marginBottom(30px !important);
}

.mb--32 {
  @include marginBottom(30px !important);
}

.mb--40 {
  @include marginBottom(40px !important);
}

.mb--48 {
  @include marginBottom(48px !important);
}

.mb--61 {
  @include marginBottom(61px !important);
}

.mb--63 {
  @include marginBottom(63px !important);
}

.mb--67 {
  @include marginBottom(67px !important);
}

.mb--n-4 {
  @include marginBottom(-4px !important);
}

.mb--n-12 {
  @include marginBottom(-12px !important);
}

.pt--5 {
  @include paddingTop(5px !important);
}

.pt--16 {
  @include paddingTop(16px !important);
}

.pt--24 {
  @include paddingTop(24px !important);
}

.pt--25 {
  @include paddingTop(25px !important);
}

.pl--8 {
  @include paddingLeft(8px !important);
}

.pl--16 {
  @include paddingLeft(16px !important);
}

.pr--0 {
  @include paddingRight(0 !important);
}

.pr--8 {
  @include paddingRight(8px !important);
}

.pr--16 {
  @include paddingRight(16px !important);
}

.pb--8 {
  @include paddingBottom(8px !important);
}

.pb--16 {
  @include paddingBottom(16px !important);
}

.pb--48 {
  @include paddingBottom(48px !important);
}

.pointer {
  cursor: pointer;
}

.pointer-events--none {
  pointer-events: none;
}

.cursor-not--allowed {
  cursor: not-allowed;
}

.d--inline-block {
  @extend %d--inline-block;
}

.flex {
  @extend %flex;
}

.flex--column {
  @extend %flex--column;
}

.flex-column--reverse {
  @extend %flex-column--reverse;
}

.flex--row {
  @extend %flex--row;
}

.flex--row-reverse {
  @extend %flex--row-reverse;
}

.flex--wrap {
  @extend %flex--wrap;
}

.justify-content--center {
  @extend %justify-content--center;
}

.justify-content--end {
  @extend %justify-content--end;
}

.justify-content--start {
  @extend %justify-content--start;
}

.justify-content--between {
  @extend %justify-content--between;
}

.justify-content--around {
  @extend %justify-content--around;
}

.align-items--center {
  @extend %align-items--center;
}

.align-items--end {
  @extend %align-items--end;
}

.align-items--start {
  @extend %align-items--start;
}

.align-items--baseline {
  @extend %align-items--baseline;
}

.align-items--stretch {
  align-items: stretch;
}

.align-content--baseline {
  align-content: baseline;
}

.object-fit--cover {
  -o-object-fit: cover;
  object-fit: cover;
}

.object-fit--contain {
  -o-object-fit: contain;
  object-fit: contain;
}

.text--left {
  text-align: left;
}

.text--right {
  text-align: right;
}

.text--center {
  text-align: center;
}

.font--druck {
  font-family: $font-druck;
}

.font--light {
  font-weight: $font-light !important;
}

.font--regular {
  font-weight: $font-regular !important;
}

.font--medium {
  font-weight: $font-medium !important;
}

.font--bold {
  font-weight: $font-bold !important;
}

.font--super {
  font-weight: $font-super !important;
}

.font-size--10 {
  font-size: 0.625rem;
}

.font-size--12 {
  font-size: 0.75rem !important;
}

.font-size--14 {
  font-size: 0.875rem !important;
}

.font-size--15 {
  font-size: 0.9375rem !important;
}

.font-size--browser-default {
  font-size: 1rem;
}

.font-size--18 {
  font-size: 1.125rem !important;
}

.font-size--19 {
  font-size: 1.1875rem !important;
}

.font-size--20 {
  font-size: 1.25rem;
}

.font-size--22 {
  font-size: 1.375rem;

  &-dynamic {
    font-size: clamp(1rem, 1rem, 1.4375rem);
  }
}

.font-size--23 {
  font-size: 1.4375rem;

  &-dynamic {
    font-size: clamp(1rem, 1rem, 1.4375rem);
  }
}

.font-size--25 {
  font-size: 1.5625rem;

  &-dynamic {
    font-size: clamp(1rem, 1rem, 1.4375rem);
  }
}

.font-size--28 {
  font-size: 1.75rem;
}

.font-size--32 {
  font-size: 2rem !important;
}

.font-size--33 {
  font-size: 2.0625rem !important;
}

.font-size--50 {
  font-size: 3.125rem;
}

.font-size--56 {
  font-size: 3.5rem;
}

.font-size--60 {
  font-size: 3.75rem;
}

.font-size--72 {
  font-size: 4.5rem;
}

.text--lowercase {
  text-transform: lowercase;
}

.text--uppercase {
  text-transform: uppercase;
}

.text--capitalize {
  text-transform: capitalize;
}

.text-decoration--underline {
  text-decoration: underline;
}

.position--relative {
  position: relative;
}

.position--absolute {
  position: absolute;
}

.position--fixed {
  position: fixed;
}

.position--sticky {
  position: sticky !important;
}

.z-index--0 {
  z-index: 0;
}

.z-index--1 {
  z-index: 1;
}

.z-index--2 {
  z-index: 2;
}

.z-index--n-1 {
  z-index: -1;
}

.z-index--n-2 {
  z-index: -2;
}

.z-index--100 {
  z-index: 100;
}

.z-index--max {
  z-index: 999;
}

.overflow--hidden {
  overflow: hidden;
}

.overflow--hidden-x {
  overflow-x: hidden;
}

.overflow--auto {
  overflow: auto;
}

.opacity--10 {
  opacity: 0.1 !important;
}

.opacity--20 {
  opacity: 0.2 !important;
}

.opacity--30 {
  opacity: 0.3 !important;
}

.opacity--40 {
  opacity: 0.4 !important;
}

.opacity--50 {
  opacity: 0.5 !important;
}

.opacity--60 {
  opacity: 0.6 !important;
}

.opacity--70 {
  opacity: 0.7 !important;
}

.opacity--80 {
  opacity: 0.8 !important;
}

.opacity--100 {
  opacity: 1 !important;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.truncate--text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-lines--2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-lines--3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-lines--4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.fill--none-path {
  path {
    fill: none;
  }
}

.fill--none {
  fill: none;
}

.line-height--8 {
  line-height: 8px;
}

.line-height--12 {
  line-height: 12px;
}

.line-height--14 {
  line-height: 14px;
}

.line-height--16 {
  line-height: 16px;
}

.line-height--17 {
  line-height: 17px;
}

.line-height--19 {
  line-height: 19px;
}

.line-height--21 {
  line-height: 21px;
}

.line-height--23 {
  line-height: 23px;
}

.line-height--24 {
  line-height: 24px;
}

.line-height--28 {
  line-height: 28px;
}

.line-height--34 {
  line-height: 34px;
}

.line-height--38 {
  line-height: 38px;
}

.line-height--40 {
  line-height: 40px;
}

.line-height--47 {
  line-height: 47px;
}

.line-height--48 {
  line-height: 48px;
}

.line-height--56 {
  line-height: 56px;
}

.line-height--60 {
  line-height: 60px;
}

.line-height--72 {
  line-height: 72px;
}

.border-radius--circle {
  border-radius: $border-radius-circle;
}

.border-radius--sm {
  border-radius: $border-radius-sm !important;
}

.border-radius--md {
  border-radius: $border-radius-md;
}

.border-radius--lg {
  border-radius: $border-radius-lg;
}

.border-radius--6 {
  border-radius: 6px !important;
}

.border-radius--100px {
  border-radius: 100px !important;
}

.top--0 {
  top: 0 !important;
}

.top--10 {
  top: 10px !important;
}

.top--8 {
  top: 8px !important;
}

.top--12 {
  top: 12px !important;
}

.top--16 {
  top: 16px;
}

.top--24 {
  top: 24px;
}

.top--48 {
  top: 48px;
}

.left--0 {
  left: 0;
}

.left--12 {
  left: 12px;
}

.left--8 {
  left: 8px;
}

.left--16 {
  left: 16px;
}

.right--0 {
  right: 0px;
}

.right--8 {
  right: 8px;
}

.right--10 {
  right: 10px;
}

.right--16 {
  right: 16px !important;
}

.bottom--0 {
  bottom: 0px;
}

.bottom--12 {
  bottom: 12px;
}

.bottom--16 {
  bottom: 16px;
}

.bottom--10 {
  bottom: 10px;
}

.bottom--40 {
  bottom: 40px;
}

.bottom--50 {
  bottom: 50px;
}

.sub--heading {
  font-size: 1.25rem;
}

.caption {
  font-size: 0.75rem;
}

.ls-n--1 {
  letter-spacing: -1px;
}

.ls-n--2 {
  letter-spacing: -2px;
}

.blur-up.lazyloaded {
  -webkit-filter: blur(0);
  filter: blur(0);
}

.transition {
  transition: all 0.3s linear;
}
