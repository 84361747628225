.spinner-wrapper {
	width: 100%;
	text-align: center;
	padding: 50px 10px;
	box-sizing: border-box;
}

.loader {
	position: relative;
	margin: 0 auto;
	width: 60px;
	height: 100%;
}

.loader:before {
	content: '';
	display: block;
	padding-top: 100%;
}

.circular {
	animation: rotate 2s linear infinite;
	-webkit-animation: rotate 2s linear infinite;
	height: auto;
	transform-origin: center center;
	-webkit-transform-origin: center center;
	width: 100%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.solid-spinner {
	.path {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
		animation: dash 1.5s ease-in-out infinite;
		-webkit-animation: dash 1.5s ease-in-out infinite;
		strokelinecap: round;
		stroke-width: 3px;
	}
}

.path {
	stroke-dasharray: 1, 200;
	stroke-dashoffset: 0;
	animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
	-webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
	strokelinecap: round;
}

@keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}

	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}

@keyframes color {
	100%,
	0% {
		stroke: #d62d20;
	}

	40% {
		stroke: #0057e7;
	}

	66% {
		stroke: #008744;
	}

	80%,
	90% {
		stroke: #ffa700;
	}
}

@-webkit-keyframes rotate {
	100% {
		transform: rotate(360deg);
	}
}

@-webkit-keyframes dash {
	0% {
		stroke-dasharray: 1, 200;
		stroke-dashoffset: 0;
	}

	50% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -35px;
	}

	100% {
		stroke-dasharray: 89, 200;
		stroke-dashoffset: -124px;
	}
}

@-webkit-keyframes color {
	100%,
	0% {
		stroke: #d62d20;
	}

	40% {
		stroke: #0057e7;
	}

	66% {
		stroke: #008744;
	}

	80%,
	90% {
		stroke: #ffa700;
	}
}

.form-loader {
	position: relative;

	.form-overlay-loader {
		z-index: 111;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		background-color: rgba($color: #ffffff, $alpha: 0.75);
	}
}
