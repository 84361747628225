// Theme color palette variables

$primary: #000000;
$white-color:"#FFFFFF";

//Font family
$font: 'Inter';
$font-druck: 'Druck';

//Font Weight Variables
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;
$font-super: 900;

//latter Spacing variables
$letter-spacing-base: 0.5px !default;
$letter-spacing-lg: 2px !default;
$letter-spacing-xl: 4px !default;
$l-space-n-base: -1px !default;

//Line Height variables
$line-height-default: 1.5;

//Font size variables
$font-size-xs: 0.8125rem;
$font-size-sm: 0.875rem;
$font-size-default: 0.9375rem; // 0.9375rem = 15px
$font-size-browser-default: 1rem;
$font-size-md: 1.0625rem;
$font-size-lg: 1.125rem;
$font-size-xl: 1.5rem;

// width and height values variables
$full-width: 100%;
$full-height: 100%;

//Container width variables

//Border radius width variables
$border-radius-default: 6px;
$border-radius-xs: 2px;
$border-radius-sm: 4px;
$border-radius-md: 8px;
$border-radius-lg: 10px;
$border-radius-circle: 50%;
