@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .navButton {
    @apply buttonClass text-small hover:border-secondary hover:text-black w-sm h-sm hover:border-3 font-normal text-white gap-1.5 flex  tracking-tightest justify-center  items-center  max-sm:text-tiny rounded-4xl max-xs:hidden;
  }

  .navButton:hover {
    background-image: linear-gradient(
      to right,
      rgba(140, 237, 19, 1),
      rgba(84, 187, 12, 1)
    );
    background: transparent;
  }

  .navButton:hover .icon {
    filter: brightness(1) invert(1) !important;
  }
  body {
    @apply bg-primary;
  }
  .buttonClass {
    background-image: linear-gradient(
      to right,
      rgba(140, 237, 19, 1),
      rgba(84, 187, 12, 1)
    );
  }
  .app-main {
    @apply bg-white;
  }

  .failTitle {
    @apply text-extraTiny font-normal leading-10 text-white 2xl:mt-20  max-md:mt-40 max-lg:mt-28 md:mt-16 mb-16;
  }

  .bankHeader {
    @apply max-sm:pb-2 lg:pt-12 lg:pb-12 max-sm:mt-8 max-md:text-largeSm text-largeSm font-normal underline text-mediumGreen;
  }

  .screenContainer {
    @apply bg-primary screenHeight flex justify-center;
  }
  .screenContainerPlay {
    @apply bg-primary screenHeightHub flex justify-center;
  }

  .screenHeight,
  .screenHeightHub {
    height: calc(100vh - 88px);
  }

  .inner {
    position: absolute;
    width: 60%;
    height: 0.5px;
    background: black;
    top: 58%;
    left: 20%;
    transform: rotate(90deg);
  }
  .navPlay {
    @apply text-mediumLg font-normal  tracking-tightest hover:text-secondary max-md:hidden no-underline;
  }

  .headerPlay {
    @apply max-md:block hidden font-normal max-sm:mt-8 underline text-mediumLg text-secondary text-center;
  }

  .headerWrapper {
    @apply py-20 max-md:pb-small max-md:pt-7 max-xs:pb-tiny;
  }

  .headerContainer {
    @apply flex items-center flex-col justify-center  max-md:justify-start max-md:px-5;
  }

  .gameContainer {
    @apply flex items-center flex-col justify-around  max-md:justify-start max-md:px-5;
  }
  .boxContainer {
    @apply flex max-sm:gap-2 md:gap-4;
  }

  .beginBox {
    @apply w-28 h-40 max-md:h-24  max-md:w-16 shadow-xs  max-sm:w-12 max-sm:h-24 flex items-center border-yellow border-7 max-md:border-4 rounded-[25px] max-md:rounded-2xl justify-center text-4xl max-md:text-mediumXs font-normal bg-lightYellow max-md:m-0 max-xs:h-20 max-xs:w-10 max-xs:border-4 max-xs:text-2xl max-xs:rounded-2xl;
  }

  .beginArrow {
    @apply rounded-4xl bg-lightBlue w-14 h-14 max-sm:w-10 max-sm:h-10 flex justify-center max-xs:h-8;
  }

  .solveArrow {
    @apply rounded-4xl items-center bg-lightGreen hover:bg-green w-14 h-14 max-sm:w-10 max-sm:h-10 flex justify-center max-xs:h-8 max-xs:w-8;
  }

  .buttonContainer {
    @apply flex max-md:flex-col-reverse gap-14 max-md:gap-6 pt-3;
  }

  .solveTime {
    @apply text-extraSmall max-md:text-mediumSmall text-center max-md:mb-14 mt-7 max-lg:mb-extraSmall font-medium text-white;
  }

  .timeRemaining {
    @apply text-mediumSm tracking-tightest leading-8  text-center mt-16 max-md:text-xl font-bold text-white;
  }

  .failTime {
    @apply text-2xl text-center  max-sm:mt-8 max-lg:mt-28 md:mt-20 mt-24 pb-4 font-normal text-white;
  }

  .failSubtitle {
    @apply text-2xl text-center pb-8 font-normal text-white;
  }

  .failBox {
    @apply flex max-sm:gap-2 md:gap-5;
  }

  .time {
    @apply text-extraSmall  text-center mt-9 mb-8 font-medium text-white;
  }

  .solveHeader {
    @apply max-sm:pb-16 lg:pt-12 lg:pb-12 text-largeSm font-normal underline text-mediumGreen;
  }
  .boxWrapper {
    @apply flex flex-col justify-center items-center gap-6 max-md:gap-4;
  }
  .arrowIcon {
    @apply max-md:w-4 max-md:h-3;
  }
  .navWrapper {
    @apply flex items-center justify-center gap-3;
  }
  .navContainer {
    @apply flex justify-between py-4 md:mx-8 max-sm:px-2;
  }
  .continueWrapper {
    @apply text-xl font-normal  max-md:text-lg max-md:font-normal leading-8 tracking-tightest text-white max-w-sm text-center;
  }

  .button {
    @apply h-lg w-lg font-bold text-2xl rounded-md max-sm:w-md  max-sm:h-md max-sm:text-mediumLg max-sm:font-normal;
  }
  .transparentButton {
    @apply button text-white border-3 border-white hover:text-black hover:bg-white;
  }

  .primaryButton {
    @apply button buttonGradient text-white hover:text-secondary hover:border-3 hover:border-secondary;
  }

  .primaryButton:hover {
    background: transparent;
  }

  .buttonGradient {
    background: linear-gradient(to right, #8ced13, #54bb0c);
  }

  .secondaryButton {
    @apply button bg-white border-3 border-white hover:text-white hover:bg-transparent;
  }

  .orientation {
    display: none;
  }

  .continueResponsive {
    @apply max-xs:py-0.5;
  }
  .homePuzzle {
    @apply pb-11 max-lg:mt-xl;
  }

  .overTitle {
    @apply text-mediumSmall max-xs:text-center font-normal text-white;
  }

  .homeSubtitle {
    @apply text-center text-white leading-8 tracking-tightest max-md:max-w-sm font-normal text-largeSm max-md:text-2xl pb-14 max-md:pt-6 max-md:pb-11 pt-4;
  }

  .overDescription {
    @apply text-center text-white  leading-8 tracking-tightest max-w-lg font-normal text-largeSm max-md:text-2xl pb-8 max-md:pb-11 pt-8;
  }
  .completePara {
    @apply text-white text-center font-normal max-sm:pb-0.5 max-w-3xl-minus-1rem leading-normal max-md:leading-8 max-md:max-w-md tracking-tightest max-md:text-xl text-mediumXl pb-20 max-md:pt-16 pt-3;
  }

  .wordHeader {
    @apply w-[80%] flex flex-col justify-center items-center md:gap-20 max-sm:gap-14;
  }
  .wordText {
    @apply flex  items-end justify-center text-mediumXl max-md:text-xl border-b h-20 max-md:h-12 font-normal border-black;
  }

  .bankWrapper {
    @apply text-mediumXl font-normal py-6 max-md:text-largeSm;
  }

  .wordButton {
    @apply flex justify-center items-center h-[42px] w-[204px] max-md:w-24 max-md:h-5 max-md:text-sm  text-mediumXl font-normal mb-5;
  }

  .wordWrapper {
    @apply max-sm:px-12 grid grid-cols-2 w-full relative max-xs:px-0;
  }

  .popWrapper {
    @apply w-large h-large max-md:top-[21%] max-sm:w-[366px] max-md:h-[26rem] bg-smallGreen rounded-large mb-8 absolute top-[24%] max-xs:w-[261px] max-xs:top-[23%] max-xs:h-[24rem];
  }

  @media screen and (min-height: 600px) and (max-height: 740px) {
    .solution {
      margin-bottom: 2.5rem !important;
      margin-top: 2rem !important;
    }
    .timeRemaining {
      margin-top: 2rem !important;
    }
    .headerLogo {
      margin-top: 0.375rem !important;
    }
    .screenContainerPlay {
      height: unset !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .screenHeight,
    .screenHeightHub {
      height: calc(100vh - 59.62px);
    }
    .inner {
      width: 79%;
      top: 58%;
      left: 11%;
    }
  }
  @media screen and (max-width: 370px) {
    .screenHeight,
    .screenHeightHub {
      height: calc(100vh - 69.55px);
    }
  }
}
