*,
*::after,
*::before {
  box-sizing: inherit;
}

* {
  font-family: "Wellfleet";
  user-select: none;
  outline: none;
}

html {
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

body {
  @include margin(0);
  @include padding(0);
  color: $primary;
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: white;
}

button {
  cursor: pointer;
  outline: none;
}

a {
  text-decoration: none;
}

ul {
  @include padding(0);
}

ul {
  list-style: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  @include margin(0);
}

input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  resize: none;
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;

  &::-webkit-scrollbar {
    width: 0;
  }
}

img {
  will-change: filter;
}

button::-moz-focus-inner {
  border: 0;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

input {
  -webkit-appearance: none;
  user-select: text;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

pre {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  font-size: 0.75rem;
  line-height: 20px;
}

iframe {
  border: none;
}

h1 {
  font-size: 3rem;

  &.size--small {
    font-size: 2.125rem;
  }
}

h2 {
  font-size: 2.4375rem;

  &.size--small {
    font-size: 2.0625rem;
  }
}

h3 {
  font-size: 2.0625rem;

  &.size--small {
    font-size: 1.75rem;
  }
}

h4 {
  font-size: 2.0625rem;

  &.size--small {
    font-size: 1.4375rem;
  }
}

h5 {
  font-size: 1.4375rem;

  &.size--small {
    font-size: 1.1875rem;
  }
}

h6 {
  font-size: 1.1875rem;

  &.size--small {
    font-size: 1rem;
  }
}

p {
  font-size: 0.875rem;
  line-height: 17px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  @include margin(0);
}
