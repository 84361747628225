@font-face {
  font-family: "Inter";
  font-display: swap;
  font-weight: 300;
  src: url("../fonts/inter/Inter-Light.ttf");
}

@font-face {
  font-family: "Inter";
  font-display: swap;
  font-weight: 400;
  src: url("../fonts/inter/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter";
  font-display: swap;
  font-weight: 500;
  src: url("../fonts/inter/Inter-Medium.ttf");
}

@font-face {
  font-family: "Inter";
  font-display: swap;
  font-weight: 600;
  src: url("../fonts/inter/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "Inter";
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/inter/Inter-Bold.ttf");
}

@font-face {
  font-family: "Druck";
  font-display: swap;
  font-weight: 500;
  src: url("../fonts/druck/DrukText-Medium.otf");
}

@font-face {
  font-family: "Druck";
  font-display: swap;
  font-weight: 700;
  src: url("../fonts/druck/DrukText-Bold.otf");
}

@font-face {
  font-family: "Druck";
  font-display: swap;
  font-weight: 900;
  src: url("../fonts/druck/DrukText-Heavy.otf");
}

@font-face {
  font-family: "Wellfleet";
  font-display: swap;
  //   font-weight: 500;
  src: url("../fonts/Wellfleet/Wellfleet-Regular.ttf");
}
