.btn {
	@include button();

	&:disabled {
		cursor: not-allowed;
	}

	&:not(:disabled) {
		&:active {
			transform: scale(0.97);
		}
	}
}
